.wrapper
	display: flex

.locale
	margin-right: 5px
	font-size: 20px
	line-height: 27px
	opacity: 50%
	color: #000
	&:hover
		text-decoration: underline
	&.invertedLocale
		color: #FFF

.activeLocaleCode
	opacity: 1
