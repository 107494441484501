@use 'sass:math'
@use '../app/styles/common'

.wrapper
	margin-bottom: -30px
	grid-column: 4/9

.imageWrapper
	position: relative
	margin: 25px 0

.image
	position: relative
	padding-top: math.div(100%, math.div(342, 205))

.imageDescription
	position: absolute
	bottom: 0
	left: 0
	right: 0
	color: #FFF
	font-size: 20px
	line-height: math.div(27,20)
	padding: 20px

.videoWrapper,
.ytVideo
	margin: 25px 0
	position: relative
	&::before
		background: #000
		position: relative
		display: block
		content: ""
		padding-top: math.div(100%, math.div(342, 205))
		@media screen and ( min-width: common.$break768 )
			padding-top: math.div(100%, math.div(342, 205))

.video,
.iframe
	position: absolute
	top: 0
	bottom: 0
	right: 0
	left: 0
	width: 100%
	height: 100%
