@use "sass:math"

.wrapper
	// color: #6B6B6B
	color: var(--buttonLink-color, #6B6B6B)
	cursor: pointer
	border: none
	background: transparent
	font-size: 20px
	line-height: math.div(65, 20)
	font-weight: 700
	text-decoration: underline
	display: flex
	align-items: center
	&:hover
		text-decoration: none
		.arrow
			transform: translateX(15px)

.arrow
	margin-left: 13px
	display: flex
	justify-content: center
	align-items: center
	transition: transform 0.3s
