@use '../app/styles/common'

@keyframes translateUp
	0%
		transform: translateY(100%)
	100%
		transform: translateY(0)

.wrapper
	z-index: 10
	padding: 40px 0
	position: sticky
	bottom: 0
	right: 0
	left: 0
	background: #AB9574
	color: #FFF
	animation-name: translateUp
	animation-duration: 1s
	&.isClosed
		display: none

.titleWrapper
	font-size: 20px
	font-weight: 700
	display: flex
	justify-content: space-between
	@media screen and ( min-width: common.$break480 )
		font-size: 30px

.crossButton
	cursor: pointer
	padding: 0
	border: none
	background: transparent
	display: flex
	justify-content: space-between
	width: 20px
	&:hover
		.line1,
		.line2
			background: darken(#FFF, 15% )

.line1,
.line2
	background: #FFF
	height: 25px
	width: 2px

.line1
	transform: rotate(-45deg)
	transform-origin: top right

.line2
	transform: rotate(45deg)
	transform-origin: top left

.text
	font-size: 16px
	line-height: 1.4
	margin: 20px 0 30px 0
	@media screen and ( min-width: common.$break480 )
		font-size: 20px

.toggleWrapper
	display: none
	&.showToggle
		display: block

.inputItems
	display: inline-block

.detailButtonsWrapper
	margin-top: 30px
	@media screen and ( min-width: common.$break768 )
		display: flex

.backButton
	cursor: pointer
	display: flex
	align-items: center
	justify-content: center
	border: none
	background: transparent
	color: #fff
	text-decoration: underline
	font-size: 19px
	@media screen and ( min-width: common.$break768 )
		font-size: 23px
	&:hover
		text-decoration: none

.buttonsWrapper
	@media screen and ( min-width: common.$break768 )
		display: flex
	&.hideButtons
		display: none

.moreButton,
.consentButton,
.acceptSelectedButton
	display: block
	border-radius: 10px
	cursor: pointer
	border: none
	background: lighten(#57606f, 10% )
	color: #fff
	font-size: 17px
	padding: 15px 30px
	font-weight: 700
	@media screen and ( min-width: common.$break480 )
		font-size: 20px
	&:hover
		background: #57606f
		text-decoration: underline

.acceptSelectedButton
	margin-bottom: 30px
	@media screen and ( min-width: common.$break768 )
		margin-right: 30px
		margin-bottom: 0

.moreButton
	margin-bottom: 30px
	@media screen and ( min-width: common.$break768 )
		margin-right: 40px
		margin-bottom: 0
