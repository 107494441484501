@use '../app/styles/common'

@keyframes fadeOut
	100%
		opacity: 0

.wrapper
	position: fixed
	top: 30px
	left: 50%
	transform: translate(-50%)
	color: #000
	padding: 20px 30px
	font-size: 40px
	text-transform: uppercase
	background: #ddf2dc
	border: 2px solid #a3cfa1
	display: flex
	justify-content: center
	align-items: center
	border-radius: 15px
	animation: fadeOut 1s 4s forwards
	@media screen and ( min-width: common.$break768 )
		font-size: 30px
