@use 'sass:math'
@use '../app/styles/common'

.subTitle
	color: #575757
	font-size: 20px
	text-align: center
	line-height: math.div(23.5, 20)
	margin-bottom: 25px
	@media screen and ( min-width: common.$break768 )
		margin-bottom: 10px
		line-height: math.div(27, 20)

.title
	display: flex
	justify-content: center

.titleIn
	font-weight: 700
	font-size: 40px
	line-height: math.div(90, 80)
	color: #AB9574
	text-align: center
	@media screen and ( min-width: common.$break768 )
		max-width: 949px
		font-size: 80px

.membersList
	margin-top: 35px
	display: grid
	grid-template-columns: repeat(2, 1fr)
	gap: 15px
	padding-bottom: 133px
	@media screen and ( min-width: common.$break768 )
		margin-top: 80px
		gap: 30px
		grid-template-columns: repeat(3, 1fr)

.member
	cursor: pointer
	position: relative
	&:nth-child(2n)
		top: 133px
	@media screen and ( min-width: common.$break768 )
		&:nth-child(2n)
			top: 0
		&:nth-child(3n + 2)
			top: 40%
		&:nth-child(3n + 3)
			top: 80%
