@use 'sass:math'
@use '../app/styles/common'

.title
	font-size: 20px
	line-height: math.div(23.5, 20)
	color: #6B6B6B
	margin-bottom: 20px
	margin-top: 130px
	@media screen and ( min-width: common.$break992 )
		margin-top: 200px

.articles
	@media screen and ( min-width: common.$break992 )
		display: grid
		grid-template-columns: repeat(9, 1fr)
		gap: 70px

.mainArticlesList
	grid-column: 1/6
	display: grid
	gap: 30px
	@media screen and ( min-width: common.$break768 )
		grid-template-columns: repeat(2, 1fr)

.otherArticlesSection
	grid-column: 6/10
	margin-bottom: -50px
	margin-top: 100px
	@media screen and ( min-width: common.$break992 )
		margin-top: 0

.otherArticle
	margin-bottom: 50px
