@use 'sass:math'
.wrapper
	position: relative
	background: #AB9574
	color: #FFF
	padding: 30px 25px

.button
	margin-top: 30px
	margin-right: 25px
	cursor: pointer
	position: absolute
	right: 0
	top: 0
	width: 15px
	height: 15px
	border: none
	background: transparent

.buttonWrapper
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0

.line1,
.line2
	background: #FFF
	position: absolute
	right: 50%
	top: 50%
	height: 17px
	width: 2px

.line1
	transform: translate(-50%, -50%) rotate(45deg)

.line2
	transform: translate(-50%, -50%) rotate(-45deg)

.name
	margin-bottom: 25px

.name,
.text
	font-size: 20px
	line-height: math.div(27,20)
