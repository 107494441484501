@use 'sass:math'
@use '../app/styles/common'

.wrapper
	margin-top: 30px
	margin-bottom: 100px
	@media screen and ( min-width: common.$break768 )
		margin-top: 110px
		margin-bottom: 200px

.textContent
	margin-bottom: 30px
	@media screen and ( min-width: common.$break768 )
		margin-bottom: 150px
		display: grid
		grid-template-columns: 1fr 1fr

.title
	font-weight: 700
	font-size: 40px
	line-height: math.div(45,40)
	color: #AB9574
	margin-bottom: 30px
	@media screen and ( min-width: common.$break768 )
		margin-bottom: 0
	@media screen and ( min-width: common.$break992 )
		font-size: 80px

.text
	font-size: 20px
	line-height: math.div(27,20)
	color: #AB9574
	@media screen and ( min-width: common.$break992 )
		font-size: 25px

.tilesList
	@media screen and ( min-width: common.$break768 )
		display: grid
		grid-template-columns: 1fr 1fr
		gap: 25px
