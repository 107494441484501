@use 'sass:math'
@use '../app/styles/common'

.wrapper
	color: var(--serviceType-color, #AB9574)
	margin-top: 150px
	@media screen and ( min-width: common.$break992 )
		margin-top: 250px

.title
	font-weight: 700
	margin-bottom: 40px
	font-size: 40px
	line-height: math.div(65,60)
	text-align: center
	@media screen and ( min-width: common.$break768 )
		font-size: 60px

.textWrapper
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	width: 100%

.text
	line-height: math.div(33.75, 25)
	text-align: center
	max-width: 567px
	font-size: 20px
	@media screen and ( min-width: common.$break768 )
		font-size: 25px

.button
	margin-top: 30px
	display: flex
	justify-content: center
