@use 'sass:math'
@use '../app/styles/common'

.wrapper
	position: relative

.imageWrapper
	flex: 0 0 auto

.image
	position: relative
	padding-top: math.div(100%, math.div(538,486))

.text
	position: absolute
	bottom: 0
	padding: 30px
	color: #FFF
	font-size: 20px
	line-height: math.div(27, 20)

.description
	margin-top: 15px

.arrows
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	padding: 0 20px
	display: flex
	justify-content: space-between
	align-items: center
	z-index: 1
	@media screen and ( min-width: common.$break480 )
		padding: 0 30px

.leftArrow,
.rightArrow
	color: #FFF
	cursor: pointer
	border: none
	background: transparent

.leftArrow
	transform: rotate(180deg)
