@use 'sass:math'
@use '../app/styles/common'

.wrapper
	margin-top: 118px
	@media screen and ( min-width: common.$break768 )
		display: flex

.title
	display: flex
	justify-content: center
	align-items: center
	font-size: 20px
	color: #575757
	line-height: math.div(23.5,20)
	margin-bottom: 50px
	@media screen and ( min-width: common.$break768 )
		line-height: math.div(27,20)
		margin-right: 70px
		margin-bottom: 30px
	@media screen and ( min-width: common.$break992 )
		margin-right: 100px
		margin-bottom: 0

.logos
	width: 100%
	display: grid
	grid-template-columns: repeat(2, 1fr)
	gap: 11px
	max-width: 400px
	margin: 0 auto
	@media screen and ( min-width: common.$break768 )
		max-width: none
		margin: 0
		grid-template-columns: repeat(4, 1fr)
		justify-content: space-between

.logo
	position: relative
	padding-top: math.div(100%, math.div(110, 55))
