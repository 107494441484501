@use 'sass:math'
@use '../app/styles/common'

.navigation
	position: fixed
	background: #AB9574
	left: 0
	right: 0
	max-height: 100vh
	overflow: auto
	-webkit-overflow-scrolling: auto
	padding: 35px 0
	@media screen and ( min-width: common.$break480 )
		padding: 45px 0
	@media screen and ( min-width: common.$break768 )
		padding: 60px 0

.contentWrapper
	display: flex
	flex-direction: column
	@media screen and ( min-width: common.$break992 )
		flex-direction: row
		justify-content: space-between

.logoPosition
	display: flex
	justify-content: center
	order: 100
	margin-top: 60px
	@media screen and ( min-width: common.$break480 )
		margin-top: 100px
	@media screen and ( min-width: common.$break992 )
		order: 0
		margin-top: 0
		display: block

.mobileLogoPosition
	display: flex
	align-items: center
	margin-top: 30px
	@media screen and ( min-width: common.$break992 )
		display: none

.logoWrapper
	width: 150px

.logo
	position: relative
	padding-top: math.div(100%, math.div(93, 27))

.navigationLinks
	margin-top: 30px
	width: 100%
	@media screen and ( min-width: common.$break992 )
		margin-left: 45px
		display: flex
		justify-content: space-between
		margin-top: 0

.servicesList
	display: grid
	gap: 10px
	margin-top: 20px
	@media screen and ( min-width: common.$break992 )
		margin-top: 0
	@media screen and ( min-width: common.$break1200 )
		//margin-right: 80px

.serviceSectionTitle
	font-size: 30px
	color: #FFF
	@media screen and ( min-width: common.$break992 )
		display: none

.link
	color: #FFF
	font-size: 30px
	line-height: math.div(27, 20)
	&:hover
		text-decoration: underline
	@media screen and ( min-width: common.$break992 )
		text-align: center
		font-size: 20px
		margin-right: 15px

.rightSection
	display: flex
	justify-content: space-between
	order: -100
	@media screen and ( min-width: common.$break992 )
		order: 0
		justify-content: flex-start

.closeButtonWrapper
	display: flex
	justify-content: center
	align-items: center
	border: none
	cursor: pointer
	background: transparent
	@media screen and ( min-width: common.$break992 )
		margin-left: 40px
		margin-right: 20px
		align-items: flex-start

.closeButton
	width: 30px
	height: 30px
	display: flex
	justify-content: center
	align-items: center

.line1,
.line2
	width: 2px
	height: 17px
	background: #FFF
	position: absolute

.line1
	transform: rotate(-45deg)

.line2
	transform: rotate(45deg)

.closeButtonTitle
	font-size: 20px
	line-height: math.div(27,20)
	color: #FFF
	@media screen and ( min-width: common.$break992 )
		display: none
