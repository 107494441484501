@use '../app/styles/common'

.input
	border: none
	width: 100%
	padding: 12px
	font-size: 20px
	&::placeholder
		color: lighten(#575757, 50%)
		transition-property: color
		transition-duration: 0.3s
	&:focus
		outline: none
		&::placeholder
			color: #575757
	@media screen and ( min-width: common.$break768 )
		padding: 15px
