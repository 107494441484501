@use 'sass:math'
@use '../app/styles/common'

.wrapper
	cursor: pointer
	position: relative
	margin-bottom: 5px
	overflow: hidden
	@media screen and ( min-width: common.$break768 )
		&:hover
			.hoveredDiv
				opacity: 1
				visibility: visible
			.tileTitle
				z-index: 1
				text-decoration: underline
				font-size: 25px
				@media screen and ( min-width: common.$break992 )
					font-size: 30px

			.tileImage
				transform: scale(1.1)

.tileTitle
	width: 100%
	padding: 22px
	position: absolute
	z-index: 10
	top: 0
	line-height: math.div(35.25,30)
	color: #FFF
	font-size: 27px
	display: flex
	justify-content: space-between
	@media screen and ( min-width: common.$break480 )
		font-size: 30px

.arrow
	margin-top: 7px
	@media screen and ( min-width: common.$break768 )
		display: none

.tileImage
	position: relative
	padding-top: math.div(100%, math.div(373,224))
	transition: .3s transform
	overflow: hidden

	&::before
		content: ''
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		background-color: rgba(171, 149, 116, 0.3)
		z-index: 1

	img
		filter: grayscale(100%) sepia(10%) contrast(90%) brightness(105%)
		transition: all 0.3s ease

.hoveredDiv
	opacity: 0
	visibility: hidden
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	background: #AB9574
	display: flex
	justify-content: space-between
	align-items: flex-end
	transition: .3s opacity,visibility
	padding: 0 20px
	padding-bottom: 15px
	@media screen and ( min-width: common.$break992 )
		padding: 0 30px 30px 20px
	@media screen and ( min-width: common.$break1200 )
		padding: 0 50px 50px 20px

.tileText
	max-width: 290px
	line-height: math.div(27,20)
	color: #FFF
	font-size: 17px
	@media screen and ( min-width: common.$break992 )
		font-size: 20px

.tileArrow
	color: #FFF
	margin-bottom: 5px
