@use 'sass:math'
@use '../app/styles/common'

.content
	display: flex
	flex-direction: column
	@media screen and ( min-width: common.$break992 )
		margin: 95px 0
		display: grid
		grid-template-columns: repeat(7, 1fr)
		gap: 40px

.textWrapper
	display: contents
	@media screen and ( min-width: common.$break992 )
		grid-column: 1/4
		display: block

.button
	position: absolute
	top: 0
	margin-top: 39px
	@media screen and ( min-width: common.$break768 )
		margin-top: 60px
	@media screen and ( min-width: common.$break992 )
		position: relative
		margin-top: 0

.title
	line-height: math.div(45,40)
	color: #000000
	font-size: 33px
	margin-top: 45px
	@media screen and ( min-width: common.$break768 )
		font-size: 40px
	@media screen and ( min-width: common.$break992 )
		margin-top: 0

.labelsList
	order: 2
	margin: 50px 0
	@media screen and ( min-width: common.$break992 )
		margin-bottom: 0

.labelWrapper
	display: flex
	margin-bottom: 15px

.iconWrapper
	flex-shrink: 0
	width: 25px
	margin-right: 10px

.icon
	position: relative
	padding-top: 100%
	width: 100%

.labelTitle
	line-height: math.div(40,25)
	color: #AB9574
	font-size: 15px
	@media screen and ( min-width: common.$break480 )
		font-size: 20px

.media
	margin: 0 -24px
	margin-top: 30px
	@media screen and ( min-width: common.$break992 )
		margin: 0
		grid-column: 4/8

.ytVideo,
.videoWrapper
	position: relative
	&::before
		background: #000
		display: block
		content: ""
		position: relative
		padding-top: math.div(100%, math.div(744,600))
		@media screen and ( min-width: common.$break768 )
			padding-top: math.div(100%, math.div(744,445))

.iframe,
.video
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	width: 100%
	height: 100%

.image
	position: relative
	padding-top: math.div(100%, math.div(744,445))
