@use 'sass:math'
@use '../app/styles/common'

.mainSection
	position: relative
	@media screen and ( min-width: common.$break992 )
		display: flex
		margin-top: 55px

.text
	font-size: 22px
	line-height: math.div(27,20)
	color: var(--serviceType-color, #AB9574)
	margin-top: 40px
	margin-bottom: 65px
	@media screen and ( min-width: common.$break992 )
		width: 50%
		margin-top: 30px
		margin-right: 50px
		font-size: 25px
	a
		color: #2e4da1
		text-decoration: underline
		&:hover
			text-decoration: none

.title
	font-weight: 700
	color: var(--serviceType-color,#000)
	font-size: 40px
	line-height: math.div(45,40)
	@media screen and ( min-width: common.$break992 )
		margin-top: 70px
		font-size: 60px
		max-width: 680px

.backgroundImg
	z-index: -1
	display: none
	@media screen and ( min-width: common.$break992 )
		display: block
		position: absolute
		top: 0

.mobileImage
	overflow: hidden
	margin: 0 -24px
	@media screen and ( min-width: common.$break992 )
		display: none

$scrollbar-width: 20px // ugh, ugly
.backgroundImgMobileWrapper
	position: relative
	width: calc( 100% + 48px)
	left: calc(50% + 50vw - #{$scrollbar-width} / 2)
	transform: translateX(-100%)
	max-width: 250px
	@media screen and ( min-width: common.$break480 )
		max-width: 400px

.backgroundImgMobile
	--serviceBackgroundImg-aspectRatio: math.div(282, 410)
	position: relative
	padding-top: calc(100%/ var(--serviceBackgroundImg-aspectRatio))

.titleIconWrapper
	width: 1em
	height: 1em
	display: inline-block

.titleIcon
	position: relative
	padding-top: 100%
	top: .3em

.imageCarousel
	z-index: 0
	margin: 0 -24px
	flex: 1 0 auto
	@media screen and ( min-width: common.$break992 )
		width: 50%
		margin: 0
		margin-left: 50px

.media
	margin-top: 80px
	@media screen and ( min-width: common.$break992 )
		margin-top: 140px
		padding: 0 150px
	@media screen and ( min-width: common.$break1200 )
		margin-top: 200px

.ytVideo,
.videoWrapper
	position: relative
	&::before
		background: #000
		position: relative
		display: block
		content: ""
		padding-top: math.div(100%, math.div(1005,800))
		@media screen and ( min-width: common.$break768 )
			padding-top: math.div(100%, math.div(1005,488))

.iframe,
.video
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	width: 100%
	height: 100%

.realizationsTitle
	font-size: 20px
	line-height: math.div(27,20)
	color: #575757
	margin-top: 100px
	margin-bottom: 21px
	@media screen and ( min-width: common.$break992 )
		margin-top: 250px

.recommendedRealizations
	@media screen and ( min-width: common.$break992 )
		display: grid
		grid-template-columns: repeat(3, 1fr)

.videoTitle
	position: absolute
	bottom: 0
	color: #fff
	font-size: 20px
	line-height: math.div(27,20)
	padding: 0 0 20px 23px
	@media screen and ( min-width: common.$break768 )
		padding: 0 0 40px 45px

.buttonWrapper
	@media screen and ( min-width: common.$break992 )
		display: grid
		grid-template-columns: repeat(3, 1fr)

.button
	display: flex
	justify-content: flex-end
	&.oneItem
		grid-column: 1/2
	&.twoItems
		grid-column: 2/3
	&.threeItems
		grid-column: 3/4
