@use 'sass:math'
@use '../app/styles/common'

.wrapper
	margin-top: 140px
	margin-bottom: 120px
	@media screen and ( min-width: common.$break1200 )
		margin-top: 330px
		margin-bottom: 220px

.contentWrapper
	@media screen and ( min-width: common.$break768 )
		display: grid
		grid-template-columns: repeat(4, 1fr)
		gap: 0 30px
	@media screen and ( min-width: common.$break1200 )
		gap: 0 45px

.textWrapper
	grid-column: 1/3

.subTitle
	color: #6B6B6B
	font-size: 20px
	line-height: math.div(23.5,20)
	margin-bottom: 15px

.title
	font-weight: 700
	line-height: math.div(45,40)
	color: #AB9574
	margin-bottom: 35px
	font-size: 40px
	@media screen and ( min-width: common.$break768 )
		font-size: 80px

.article
	cursor: pointer
	margin-bottom: 35px
	position: relative
	@media screen and ( min-width: common.$break768 )
		&:hover
			.tileImage
				transform: scale(1.1)
				&::after
					display: block
					opacity: 1
					visibility: visible
					background: #AB9574
			.arrowWrapper
				display: flex
			.articleText
				text-decoration: underline

.notMainEven
	grid-column: 3/5

.notMainOdd
	grid-column: 1/3

.tileImageWrapper
	overflow: hidden

.buttonWrapper
	grid-column: 3/5
