@use 'sass:math'
@use '../app/styles/common'

.wrapper
	position: relative
	margin-top: 100px
	@media screen and ( min-width: common.$break480 )
		margin-top: 200px

.image
	display: none
	@media screen and ( min-width: common.$break768 )
		display: block
		position: relative
		padding-top: math.div(100%, math.div(1440, 715))

.mobileImage
	position: relative
	padding-top: math.div(100%, math.div(397, 513))
	@media screen and ( min-width: common.$break768 )
		display: none

.content
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	padding: 65px 45px
	display: flex
	justify-content: center
	align-items: center

.titleWrapper
	display: flex
	justify-content: center
	align-items: center

.title
	font-weight: 700
	line-height: math.div(45, 40)
	color: #FFF
	text-align: center
	font-size: 30px
	@media screen and ( min-width: common.$break480 )
		font-size: 40px
	@media screen and ( min-width: common.$break768 )
		font-size: 60px
	@media screen and ( min-width: common.$break1200 )
		font-size: 80px

.button
	margin-top: 30px
	display: flex
	justify-content: center
	align-items: center
	color: #FFF
	text-decoration: underline
	font-weight: 700
	font-size: 20px
	&:hover
		text-decoration: none
		.arrow
			transform: translateX(15px)
	@media screen and ( min-width: common.$break480 )
		font-size: 25px
		margin-top: 75px
	@media screen and ( min-width: common.$break768 )
		margin-top: 30px
	@media screen and ( min-width: common.$break1200 )
		margin-top: 100px

.arrow
	display: flex
	justify-content: center
	align-items: center
	width: 34px
	flex-shrink: 0
	margin-left: 10px
	transition: transform .3s
