@use 'sass:math'
@use '../app/styles/common'

.wrapper
	overflow: hidden
	margin: 0 5px
	cursor: pointer
	position: relative
	@media screen and ( min-width: common.$break992 )
		&:hover
			.labelsWrapper
				opacity: 1
				visibility: visible
			.image,
			.videoPoster
				transform: scale(1.1)

.ytPoster
	position: relative
	padding-top: 100%

.image,
.videoPoster
	position: relative
	padding-top: 100%
	width: 100%
	transition: transform 0.3s

.playButton
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	display: none
	@media screen and ( min-width: common.$break480 )
		display: flex
		justify-content: center
		align-items: center

.title
	display: flex
	align-items: flex-end
	position: absolute
	top: 0
	bottom: 0
	right: 0
	left: 0
	line-height: math.div(27,20)
	color: #FFF
	font-size: 15px
	padding: 13px 13px 30px 20px
	font-size: 20px
	padding: 20px 20px
	@media screen and ( min-width: common.$break1200 )
		padding: 45px 20px

.labelsWrapper
	position: absolute
	top: 0
	bottom: 0
	right: 0
	left: 0
	opacity: 0
	visibility: hidden
	display: flex
	flex-direction: column
	align-items: center
	background: #AB9574
	transition-property: visibility, opacity
	transition-duration: .5s
	padding: 20px 20px
	@media screen and ( min-width: common.$break1200 )
		padding: 45px 20px
	&::before

		display: block
		content: ""
		flex-grow: 1
		flex-basis: 100%
		width: 100%

.arrow
	margin: 10px
	display: flex
	width: 30px
	justify-content: center
	align-items: center
	@media screen and ( min-width: common.$break480 )
		width: 100%
		flex-shrink: 0

.tileTextWrapper
	width: 100%
	flex-grow: 1
	flex-basis: 100%
	display: flex
	flex-direction: column
	justify-content: flex-end

.tileTitle
	text-decoration: underline
	line-height: math.div(27,20)
	color: #FFF
	font-size: 20px

.labelsList
	padding: 0 0 0 20px
	margin: 0
	margin-top: 20px

.labelTitle
	color: #FFF
	line-height: math.div(27,20)
	margin: 0
	line-height: math.div(27,20)
	font-size: 20px
