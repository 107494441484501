@use 'sass:math'
@use '../app/styles/common'

.title
	font-weight: 700
	font-size: 40px
	line-height: math.div(45,40)
	color: var(--serviceType-color, #000)
	margin-top: 80px
	margin-bottom: 25px
	@media screen and ( min-width: common.$break992 )
		font-size: 60px
		margin-bottom: 60px

.wrapper
	@media screen and ( min-width: common.$break768 )
		display: grid
		grid-template-columns: repeat(5, 1fr)
		gap: 75px
	@media screen and ( min-width: common.$break992 )
		gap: 100px
	@media screen and ( min-width: common.$break1200 )
		gap: 200px

.descriptionWrapper
	color: var(--serviceType-color)
	grid-column: 1/4
	display: grid
	gap: 50px
	@media screen and ( min-width: common.$break768 )
		display: block
		margin-bottom: -50px
	@media screen and ( min-width: common.$break1200 )
		margin-bottom: -85px

.description
	@media screen and ( min-width: common.$break768 )
		display: grid
		grid-template-columns: repeat(3, 1fr)
		margin-bottom: 50px
		gap: 20px
	@media screen and ( min-width: common.$break1200 )
		margin-bottom: 85px

.descriptionTitle
	font-size: 16px
	line-height: math.div(35, 23)
	color: #000
	color: var(--serviceType-color, #000)
	@media screen and ( min-width: common.$break768 )
		grid-column: 1/2
	@media screen and ( min-width: common.$break1200 )
		font-size: 20px

.text
	font-size: 20px
	line-height: math.div(27,20)
	color: var(--serviceType-color, #000)
	p
		margin: 0
	@media screen and ( min-width: common.$break768 )
		grid-column: 2/4
	@media screen and ( min-width: common.$break1200 )
		font-size: 25px

.imageList
	grid-column: 4/6
	display: grid
	gap: 10px
	margin-top: 80px
	@media screen and ( min-width: common.$break768 )
		margin: 0
		gap: 90px

.image
	position: relative
	padding-top: math.div(100%, math.div(382,286))
