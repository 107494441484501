@use 'sass:math'
@use '../app/styles/common'

.title
	font-weight: 700
	color: #000
	font-size: 30px
	line-height: math.div(40.5, 30)
	margin: 30px 0
	max-width: 482px
	@media screen and ( min-width: common.$break992 )
		margin-top: 85px

.articles
	@media screen and ( min-width: common.$break992 )
		display: grid
		grid-template-columns: repeat(9, 1fr)
		gap: 70px

.mainArticlesList
	grid-column: 1/6
	display: grid
	gap: 60px
	@media screen and ( min-width: common.$break480 )
		grid-template-columns: repeat(2, 1fr)
		gap: 30px
		max-height: 850px

.otherArticlesSection
	grid-column: 6/10
	margin-bottom: -50px
	margin-top: 100px
	@media screen and ( min-width: common.$break992 )
		margin-top: 0

.otherArticle
	margin-bottom: 50px
