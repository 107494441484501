@use '../app/styles/common'

.wrapper
	display: flex
	justify-content: center

.content
	max-width: 800px
	width: 100%

.title
	font-size: 30px
	font-weight: 700
	color: #000
	text-align: center
	margin-top: 100px
	@media screen and ( min-width: common.$break480 )
		font-size: 40px

.inputsWrapper
	display: grid
	gap: 15px 0
	margin: 40px 0
	@media screen and ( min-width: common.$break768 )
		margin: 60px 0
		gap: 30px 0

.note
	min-height: 300px
	border: none
	width: 100%
	padding: 15px
	font-size: 20px
	font-family: inherit
	&::placeholder
		color: lighten(#575757, 50%)
		transition-property: color
		transition-duration: 0.3s
	&:focus
		outline: none
		&::placeholder
			color: #575757

.submitButton
	display: flex
	justify-content: center
