@use 'sass:math'
@use '../app/styles/common'

.subTitle
	font-size: 20px
	color: #575757
	margin-bottom: 5px
	line-height: math.div(23.5, 20)
	@media screen and ( min-width: common.$break768 )
		line-height: math.div(27, 20)

.title
	font-weight: 700
	line-height: math.div(90,80)
	color: #AB9574
	font-size: 40px
	margin-bottom: 25px
	@media screen and ( min-width: common.$break992 )
		margin-bottom: 0
		font-size: 60px
	@media screen and ( min-width: common.$break1200 )
		font-size: 65px

.realizations
	@media screen and ( min-width: common.$break992 )
		display: grid
		grid-template-columns: repeat(3, 1fr)
		gap: 27px

.button
	font-size: 20px
	line-height: math.div(27,20)
	display: flex
	align-items: center
	text-decoration: underline
	&:hover
		text-decoration: none
		.arrow
			transform: translateX(15px)

.buttonWrapper
	padding-right: 15px
	display: flex
	align-items: flex-end
	margin-top: 23px
	@media screen and ( min-width: common.$break992 )
		justify-content: flex-end
		margin-top: 0

.arrow
	margin-left: 13px
	display: flex
	justify-content: center
	align-items: center
	transition: transform 0.3s
