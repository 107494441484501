@use 'sass:math'
@use '../app/styles/common'

.tilesList
	margin: 0 -5px
	overflow-x: scroll
	-ms-overflow-style: none /* IE and Edge */
	scrollbar-width: none /* Firefox */
	display: flex
	&::-webkit-scrollbar
		display: none
	@media screen and ( min-width: common.$break992 )
		margin: 0
		display: contents

.tile
	--visible-items-count: 1
	width: calc((100% - 50px) / var(--visible-items-count))
	flex-shrink: 0
	flex-grow: 1
	@media screen and ( min-width: common.$break768 )
		--visible-items-count: 2
	@media screen and ( min-width: common.$break992 )
		display: flex
		flex-direction: column
		width: 100%
