@use 'sass:math'
@use '../app/styles/common'

.wrapper
	margin-top: 90px
	margin-bottom: 11px
	@media screen and ( min-width: common.$break992 )
		margin-top: 170px

.logoWrapper
	width: 150px
	flex-shrink: 0
	@media screen and ( min-width: common.$break992 )
		margin-right: 33px

.logo
	position: relative
	padding-top: math.div(100%, math.div(93,33))
	margin-bottom: 25px

.content
	font-weight: 700
	width: 100%
	@media screen and ( min-width: common.$break992 )
		display: flex
		justify-content: space-between

.infoWrapper
	@media screen and ( min-width: common.$break992 )
		display: flex
		justify-content: space-between

.email,
.tel
	text-decoration: underline
	&:hover
		text-decoration: none

.email,
.tel,
.IC,
.address
	font-size: 20px
	line-height: math.div(27,20)
	color: #575757

.address
	max-width: 200px
	margin-top: 25px
	@media screen and ( min-width: common.$break992 )
		margin-top: 0
		margin-left: 42px
		margin-right: 60px

.labelList
	margin-top: 40px
	@media screen and ( min-width: common.$break992 )
		margin-top: 0

.label
	display: flex
	font-size: 20px
	line-height: 1
	color: #575757
	margin-bottom: 20px
	@media screen and ( min-width: common.$break768 )
		line-height: math.div(27, 20)

.additionalInfo
	display: flex
	flex-direction: column
	gap: .625rem

.creator
	font-size: 15px
	line-height: math.div(17.5, 15)

.termsAndConditions
	text-decoration: underline
	&:hover
		text-decoration: none
