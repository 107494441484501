@use '../app/styles/common'
@use 'sass:math'

.buttonWrapper
	font-weight: 700
	display: flex
	margin: 41px 0
	color: #575757
	@media screen and ( min-width: common.$break992 )
		margin: 0
	&.isDetailPage
		margin: 0

.arrow
	transform: rotate(180deg)
	width: 30px
	margin-right: 10px
	transition: 0.3s transform

.button
	display: flex
	justify-content: center
	align-items: center
	cursor: pointer
	font-size: 20px
	line-height: math.div(27,20)
	text-decoration: underline
	&:hover
		text-decoration: none
		.arrow
			transform: translateX(-10px) rotate(180deg)
	@media screen and ( min-width: common.$break992 )
		margin-bottom: 45px
