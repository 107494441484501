.wrapper
	display: flex

.arrowWrapper
	flex-shrink: 0
	width: 35px
	height: 25px
	position: relative

.arrow
	top: 10px
	width: 90%
	height: 3px
	background: currentColor
	position: absolute

.arrow::after,
.arrow::before
	content: ''
	position: absolute
	width: 70%
	height: 3px
	right: -6px
	background: currentColor

.arrow::after
	top: -6.5px
	transform: rotate(45deg)

.arrow::before
	top: 6.5px
	transform: rotate(-45deg)
