@use '../app/styles/common'
@use 'sass:math'

.serviceTitle
	font-size: 20px
	line-height: math.div(27,20)
	color: var(--arrowLinkTitle-color, #FFF)
	//color: #FFF
	&:hover
		text-decoration: underline
		.serviceArrow
			opacity: 1
			visibility: visible
	@media screen and ( min-width: common.$break992 )
		justify-content: space-between
	@media screen and ( min-width: common.$break1200 )
		//min-width: 297px

.serviceTitleWrapper
	white-space: nowrap

.arrow
	display: inline-block
	color: var(--arrowLinkTitle-color, #FFF)
	flex-shrink: 0
	transition-property: visibility, opacity
	transition-duration: .3s
	margin-left: 10px
	&.serviceArrow
		@media screen and ( min-width: common.$break992 )
			visibility: hidden
			opacity: 0
