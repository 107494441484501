@use 'sass:math'
@use '../app/styles/common'

.wrapper
	&:hover
		.tileImage
			@media screen and ( min-width: common.$break768 )
				transform: scale(1.1)
				&::after
					opacity: 1
					background: #AB9574
		.arrowWrapper
			@media screen and ( min-width: common.$break768 )
				visibility: visible

		.articleText
			text-decoration: underline

.tileImageWrapper
	overflow: hidden

.tileImage
	position: relative
	padding-top: math.div(100%, math.div(320, 295))
	transition: .3s transform
	&::after
		content: ""
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		background: #AB9574
		opacity: .5
		transition-property: opacity, visibility, background
		transition-duration: .5s

.arrowWrapper
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	display: flex
	justify-content: center
	align-items: center
	z-index: 1
	@media screen and ( min-width: common.$break768 )
		visibility: hidden

.arrow
	color: #FFF

.date
	font-size: 20px
	line-height: math.div(27,20)
	color: #6B6B6B
	margin: 10px 0

.articleText
	font-size: 25px
	line-height: math.div(33.75,25)
	color: #AB9574

.view-hideImageOnMobile
	.tileImage
		@media (max-width: common.$break768M)
			display: none
