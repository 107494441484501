@use 'sass:math'
@use '../app/styles/common'

.wrapper
	margin-top: 50px
	@media screen and ( min-width: common.$break768 )
		margin-top: 75px
		display: grid
		grid-template-columns: repeat(2, 1fr)
		gap: 80px
	@media screen and ( min-width: common.$break992 )
		gap: 130px

.title
	font-weight: 700
	line-height: math.div(54,40)
	color: #000
	font-size: 30px
	margin-bottom: 50px
	@media screen and ( min-width: common.$break768 )
		margin-bottom: 0
		font-size: 40px

.email,
.tel
	text-decoration: underline
	color: #AB9574
	line-height: math.div(54,40)
	font-size: 30px
	@media screen and ( min-width: common.$break768 )
		font-size: 40px
	&:hover
		text-decoration: none

.IC
	margin: 10px 0 7px 0

.IC,
.address
	font-weight: 700
	font-size: 20px
	line-height: math.div(27,20)
	color: #AB9574
