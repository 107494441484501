@use 'sass:math'
@use '../app/styles/common'

.wrapper
	margin: 40px 0
	@media screen and ( min-width: common.$break992 )
		margin: 80px 0 40px 0

.button
	position: absolute
	top: 0
	margin-top: 40px
	@media screen and ( min-width: common.$break768 )
		margin-top: 60px
	@media screen and ( min-width: common.$break992 )
		position: relative
		margin-top: 0

.contentWrapper
	@media screen and ( min-width: common.$break992 )
		display: grid
		grid-template-columns: repeat(8, 1fr)
		gap: 50px

.textContent
	grid-column: 1/4

.title
	font-weight: 700
	font-size: 30px
	line-height: math.div(40.5, 30)
	color: #000
	margin: 20px 0

.date
	font-size: 20px
	line-height: math.div(27,20)
	color: #6B6B6B

.text
	font-size: 20px
	line-height: math.div(27,20)
	color: #AB9574
	margin: 20px 0

// .tilesList
// 	margin-bottom: -30px
// 	grid-column: 4/9

// .imageTile
// 	position: relative
// 	margin: 25px 0

// .image
// 	position: relative
// 	padding-top: math.div(100%, math.div(342, 205))

// .imageDescription
// 	position: absolute
// 	bottom: 0
// 	left: 0
// 	right: 0
// 	color: #FFF
// 	font-size: 20px
// 	line-height: math.div(27,20)
// 	padding: 20px

// .videoWrapper
// 	margin: 25px 0
// 	position: relative
// 	&::before
// 		background: #000
// 		position: relative
// 		display: block
// 		content: ""
// 		padding-top: math.div(100%, math.div(342, 205))
// 		@media screen and ( min-width: common.$break768 )
// 			padding-top: math.div(100%, math.div(342, 205))

// .video
// 	position: absolute
// 	top: 0
// 	bottom: 0
// 	right: 0
// 	left: 0
// 	width: 100%
// 	height: 100%
