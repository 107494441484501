@use 'sass:math'
@use '../app/styles/common'

.wrapper
	margin-bottom: 250px
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center

.subtitle
	font-size: 20px
	line-height: math.div(27,20)
	text-align: center
	color: #575757
	margin-bottom: 20px

.title
	font-weight: 700
	line-height: math.div(27,20)
	text-align: center
	color: #AB9574
	margin-bottom: 50px
	font-size: 40px
	@media screen and ( min-width: common.$break768 )
		font-size: 80px

.mantrasList
	margin-bottom: -50px

.mantra
	display: flex
	justify-content: center
	margin-bottom: 50px
	@media screen and ( min-width: common.$break992 )
		&:nth-child(4n+2)
			margin-left: 160px
		&:nth-child(4n+3)
			margin-right: 60px
		&:nth-child(4n)
			margin-left: 210px

.mantraContent
	max-width: 840px
	display: flex

.iconWrapper
	width: 40px
	flex-shrink: 0

.icon
	flex-shrink: 0
	position: relative
	padding-top: 100%

.mantraTitle
	margin-left: 15px
	line-height: math.div(50, 30)
	color: #AB9574
	font-size: 25px
	@media screen and ( min-width: common.$break768 )
		font-size: 30px
