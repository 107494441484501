@use 'sass:math'
@use '../app/styles/common'

.wrapper
	position: relative
	&:hover
		.hoverDiv
			visibility: visible
			opacity: 1
			&::after
				content: ""
				position: absolute
				top: 0
				bottom: 0
				left: 0
				right: 0
				z-index: 1
				@media screen and ( max-width: common.$break480M )
					background: linear-gradient(to bottom, rgba(#AB9574, 0) 0%, rgba(#AB9574, 90%))

.memberImg
	position: relative
	padding-top: math.div(100%, math.div(256, 370))

.textContent
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	padding: 22px 17px
	display: flex
	flex-direction: column
	justify-content: flex-end

.name
	margin-bottom: 7px
	line-height: math.div(33, 25)
	color: #FFF
	font-size: 20px
	@media screen and ( min-width: common.$break768 )
		font-size: 25px

.description
	line-height: math.div(27, 20)
	color: #FFF
	font-size: 15px
	@media screen and ( min-width: common.$break768 )
		font-size: 20px

.hoverDiv
	overflow: hidden
	transition-property: visibility, opacity
	transition-duration: .3s
	opacity: 0
	visibility: hidden
	position: absolute
	top: 0
	bottom: 0
	right: 0
	left: 0
	background: #AB9574
	padding: 10px
	overflow: hidden
	@media screen and ( min-width: common.$break480 )
		padding: 20px

.hoverName
	font-size: 10px
	line-height: math.div(27,20)
	color: 	#FFF
	font-size: 20px
	margin-bottom: 20px

.text
	font-size: 10px
	line-height: math.div(27,20)
	color: #FFF
	font-size: 20px
	// text-overflow: ellipsis
	// overflow: hidden
	// display: -webkit-box !important
	// -webkit-box-orient: vertical
	// //display: -webkit-box
	// // display: -moz-box // Firefox
	// // display: -ms-flexbox
	// white-space: normal
	// -webkit-line-clamp: 3
	// @media screen and ( min-width: common.$break480 )
	// 	-webkit-line-clamp: 6
	// @media screen and ( min-width: common.$break768 )
	// 	-webkit-line-clamp: 8

.memberModal
	z-index: 2
	position: fixed
	top: 0
	bottom: 0
	left: 0
	right: 0
	opacity: 0
	visibility: hidden
	transition: visibility 0.3s, opacity 0.3s
	display: flex
	justify-content: center
	align-items: center
	&.isOpen
		opacity: 1
		visibility: visible

.modalContent
	max-width: 380px
	z-index: 1
	position: relative

.modalBackground
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	background-color: rgba(0,0,0,.7)
	z-index: 0
