.wrapper
	max-width: 1240px
	padding-right: 24px
	padding-left: 24px
	margin: 0 auto
	width: 100%

	&.view-narrow
		max-width: 840px

	&.view-wide
		max-width: 1440px

	&.view-disableGutters
		padding-right: 0
		padding-left: 0
