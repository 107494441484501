@use 'sass:math'
@use '../app/styles/common'

.wrapper
	top: 0
	right: 0
	left: 0
	z-index: 1
	&.invertedHeader
		position: absolute

.navigation
	z-index: 2
	position: absolute
	right: 0
	left: 0
	opacity: 0
	visibility: hidden
	transition: visibility 0.3s, opacity 0.3s

.isOpen
	opacity: 1
	visibility: visible
	position: fixed
	.navigationContent
		transform: translateY(0)

.navigationContent
	position: relative
	transform: translateY(-100%)
	transition: transform .3s
	z-index: 1

.background
	position: fixed
	top: 0
	bottom: 0
	left: 0
	right: 0
	background-color: rgba(0,0,0,.7)
	z-index: 0

.content
	display: flex
	justify-content: space-between
	padding-top: 39px
	&.detailPageContent
		justify-content: flex-end
	@media screen and ( min-width: common.$break768 )
		padding-top: 60px
	&.invertedContent
		justify-content: flex-end
	@media screen and ( min-width: common.$break992 )
		&.detailPageContent
			justify-content: space-between

.detailPageLogo
	display: none
	@media screen and ( min-width: common.$break992 )
		display: block

.logo
	width: 150px
	height: 43.55px
	position: relative
	&::after
		conten: ""
		display: block
		padding-top: math.div(100%, math.div(93,27))

.headerButtons
	display: flex
	align-items: center

.locales
	margin-right: 30px
	display: none
	@media screen and ( min-width: common.$break768 )
		display: block

.button
	cursor: pointer
	border: none
	background: transparent
	font-size: 20px
	line-height: math.div(27,20)
	display: flex
	justify-content: center
	align-items: center
	color: #000
	&.invertedButton
		color: #FFF

.linesWrapper
	display: grid
	gap: 5px
	margin-right: 5px

.line
	background: #000
	height: 2px
	width: 14px
	&.invertedLine
		background: #FFF
