@use 'sass:math'
@use '../app/styles/common'

.title
	font-weight: 700
	max-width: 485px
	margin: 20px 0
	font-size: 30px
	line-height: math.div(45,40)
	color: #000
	@media screen and ( min-width: common.$break768 )
		font-size: 40px
		margin-bottom: 40px
		margin-top: 70px

.realizationsList
	display: grid
	gap: 20px 0
	@media screen and ( min-width: common.$break768 )
		grid-template-columns: repeat(2, 1fr)
		gap: 20px
	@media screen and ( min-width: common.$break992 )
		grid-template-columns: repeat(3, 1fr)
