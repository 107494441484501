@use 'sass:math'
@use '../app/styles/common'

@keyframes scaleIn
	to
		transform: scale(1.25)

.wrapper
	position: relative

.background
	overflow: hidden
	display: none
	@media screen and ( min-width: common.$break768 )
		display: block

.backgroundIn
	padding-top: 100vh
	position: relative

.mobileBackground
	overflow: hidden
	display: block
	@media screen and ( min-width: common.$break768 )
		display: none

.mobileBackgroundIn
	position: relative
	padding-top: math.div(100%, math.div(381, 721))

.backgroundIn,
.mobileBackgroundIn
	transform: scale(1)
	animation-name: scaleIn
	animation-duration: 10s
	animation-fill-mode: forwards
	animation-timing-function: ease-out

.contentWrapper
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	padding-bottom: 25px
	padding-top: 39px
	display: flex
	@media screen and ( min-width: common.$break768 )
		padding-bottom: 55px
	@media screen and ( min-width: common.$break992 )
		justify-content: flex-end
		padding-top: 0
		padding-bottom: 68px

.heroContainer
	width: 100%
	display: flex
	flex-direction: column
	justify-content: space-between
	@media screen and ( min-width: common.$break768 )
		justify-content: flex-end

.title
	font-weight: 700
	font-size: 40px
	line-height: math.div(90,80)
	color: #FFFEFB
	max-width: 335px
	@media screen and ( min-width: common.$break768 )
		margin-top: 25px
	@media screen and ( min-width: common.$break992 )
		margin: 26px 0 70px 0
		font-size: 80px
		max-width: 913px

.labelsList
	display: grid
	gap: 15px
	margin-top: 50px
	@media screen and ( min-width: common.$break480 )
		gap: 30px
	@media screen and ( min-width: common.$break768 )
		grid-template-columns: repeat(2, 1fr)
		grid-row-gap: 30px
	@media screen and ( min-width: common.$break992 )
		margin-top: 0
		grid-row-gap: 0
		grid-template-columns: repeat(5, 1fr)

.logoWrapper
	max-width: 93px
	@media screen and ( min-width: common.$break768 )
		max-width: 267px

.logo
	position: relative
	padding-top: math.div(100%, math.div(267,75))

.label
	display: grid
	grid-template-columns: 35px auto
	gap: 20px
	@media screen and ( min-width: common.$break768 )
		display: block

.labelIconWrapper
	max-width: 35px
	height: 35px

.labelIcon
	position: relative
	padding-top: 100%

.labelTitle
	font-size: 30px
	color: #FFFEFB
	line-height: math.div(30, 40.5)
	@media screen and ( min-width: common.$break768 )
		line-height: math.div(70,30)

.labelDescription
	font-size: 20px
	line-height: math.div(27, 20)
	color: #FFFEFB
	opacity: 80%
